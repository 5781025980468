import React from "react"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import Button from "@bit/azheng.joshua-tree.button"

// Page Built in gatsby-node.js

const Coronavirus = ({ data, pageContext, location }) => {
  let language = "en"

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          title="COVID-19 Update | Amarillo Oral & Maxillofacial Surgery"
          description="Please review our latest update regarding the COVID-19 outbreak."
          pathname={location.pathname}
          schemaPage={null}
        />
        <Button buttonText={language === "es" ? "ATRÁS" : "BACK"} goBack />

        <div className="joshua-tree-content">
          <div className="columns top-section white-back">
            <div className="column"></div>
            <div className="column is-14">
              <h1 style={{ marginTop: "60px" }}>COVID-19</h1>
              <p>
                With the recent news and spread of the novel coronavirus
                (COVID-19), we know our patients have questions. As dedicated
                healthcare professionals, we are committed to the health and
                safety of our patients and staff. Our practice strictly adheres
                to and exceeds the standards for infection control by wearing
                personal protective gear, using hospital-grade disinfectants,
                practicing the latest sterilization protocols, utilizing
                single-use disposable materials, and more. Every measure is
                exercised to ensure the safety of our patients and staff.
              </p>

              <p>
                Symptoms of coronavirus are reported as cold or flu-like, with
                varying degrees of severity. While we do not know everything
                about this virus to date, individuals who are elderly and/or
                immunocompromised appear to be the most at risk for severe
                infection. To ensure your health and safety, and the health and
                safety of others at our practice, please reschedule your
                appointment if you or a member of your household have a cough,
                fever, and/or flu-like symptoms. In addition, please reschedule
                your appointment if you have traveled to any areas within the
                past 14 days that are at high risk for contracting the
                coronavirus.
              </p>

              <p>
                We thank you in advance and extend our gratitude for your
                understanding and cooperation. Together, we can help keep
                everyone as safe and healthy as possible. If you have any
                questions or concerns, please contact our office, and one of our
                staff members will be more than happy to assist you.
              </p>

              <p>
                For more information, including measures to keep yourself
                healthy, visit:
              </p>

              <p>Prevention:</p>
              <a
                target="_blank"
                href="https://www.cdc.gov/coronavirus/2019-ncov/about/prevention.html"
              >
                https://www.cdc.gov/coronavirus/2019-ncov/about/prevention.html
              </a>
              <p>Symptoms:</p>
              <a
                target="_blank"
                href="https://www.cdc.gov/coronavirus/2019-ncov/about/symptoms.html"
              >
                https://www.cdc.gov/coronavirus/2019-ncov/about/symptoms.html
              </a>
              <p>Steps When Sick:</p>
              <a
                target="_blank"
                href="https://www.cdc.gov/coronavirus/2019-ncov/about/steps-when-sick.html"
              >
                https://www.cdc.gov/coronavirus/2019-ncov/about/steps-when-sick.html
              </a>
            </div>
            <div className="column"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default Coronavirus
